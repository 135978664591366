import React from 'react';
import { motion } from 'framer-motion';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import RegistrationForm from '../Main/components/RegistrationForm/RegistrationForm';
import DemoVideo from '../Main/sections/DemoVideo/DemoVideo';
import HowItWorks from '../Main/sections/HowItWorks/HowItWorks';
import Challenge from '../Main/sections/Challenge/Challenge';
import UseCases from '../Main/sections/UseCases/UseCases';
import HowToUse from '../Main/sections/HowToUse/HowToUse';
import { CardioTitle } from '../../data/cardio.js';

import heart_full from '../../../asset/resource/heart_full.gif';
import qr_code_register from '../../../asset/resource/qr_code_register.png';

import { useCheckMobile } from '../../hooks';

import '../Main/Main.scss';
import './Touchscreen.scss';

function Touchscreen() {
  const isMobile = useCheckMobile({ width: 1024 });

  return (
    <div className="main touchscreen" id="home">
      <Header isTouchscreen />

      <div className="main__outter-bg">
        <div className="main__inner-bg">
          <section className="main__heading">
            <div className="main__heading-title">
              <h1>
                <CardioTitle />
                DISCOVER THE FUTURE WITH
                <span> TOUCH MY HEART</span>
              </h1>
              <p className="main__heading-description">
                SoftServe's R&D team collaborated with an expert cardiologist and Ultraleap to bring you an interactive
                virtual heart.
              </p>
              <p>
                Today, Touch My Heart can be used for collaboration, medical education, and patient engagement. In the
                future, it holds the potential for advancements in personalized healthcare.
              </p>
              <p>Our R&D team keeps one finger on the pulse of innovation to explore new applications of technology.</p>
              <p>
                <span>*Touch My Heart is for demo purposes only.</span>
              </p>
            </div>
            <div className="main__inview">
              <div className="main__hiro-content" width="100%">
                <img src={heart_full} alt="heart beating image" />
              </div>
            </div>
          </section>
        </div>
      </div>

      <DemoVideo />

      <div className="main__background-top">
        <HowItWorks />
      </div>

      <div className="main__background-bottom">
        <HowToUse />
      </div>

      <UseCases />

      <div className="main__section-background">
        <Challenge image={qr_code_register} className="touchscreen__challenge-qr-image" />
      </div>

      <section id="contact-us" className="main__contact-us-form">
        <div className="main__contact-us-form-wrapper">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={
              isMobile
                ? null
                : {
                    visible: { y: 0, opacity: 1 },
                    hidden: { y: -200, opacity: 0 },
                  }
            }
            transition={{ type: 'anticipate', duration: 1 }}
          >
            <div className="main__contact-us-form-title-col">
              <h2 className="main__contact-us-form-title">
                Let’s work <span>together</span>
              </h2>
              <p>
                Our R&D team empowers healthcare and life science companies to discover innovative and beneficial
                solutions.
              </p>
              <p>Submit the form and we’ll reach out soon!</p>
            </div>
          </motion.div>
          <RegistrationForm />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Touchscreen;
