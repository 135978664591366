import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import Tabs from '../../components/Tabs/Tabs';
import ResultsTable from '../../components/ResultsTable/ResultsTable';

//Assets
import human_heart_blue_image from '../../../../../asset/resource/human_heart_blue_image.png';

import { useCheckMobile } from '../../../../hooks';

import './Challenge.scss';

export const Challenge = ({ image, className }) => {
  const isMobile = useCheckMobile({ width: 767 });
  return (
    <div className="main__wrapper">
      <section id="challenge" className="challenge touchscreen__chanllenge">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 1 }}
        >
          <h2>Take The Challenge</h2>
        </motion.div>
        <article>
          <div className="challenge__content">
            <h3>Can you distinguish between a normal and abnormal heart rate?</h3>
            Use our advanced technology to identify the correct heart rate. Each unique case brings a challenge that
            requires your expertise.
            <p>
              During HLTH 2023, the Touch My Heart challenge will be available Oct. 10 until 3 p.m. PT.
              <br />
              A leaderboard will track the scores of participants. <br />
              The participant with the highest score will be notified and will receive an Oculus VR.
            </p>
          </div>
          <figure className={cn('challenge__image', className)}>
            <img width="100%" src={image || human_heart_blue_image} alt="human heart image" id="note" />
            {image && <figcaption>Scan me</figcaption>}
          </figure>
        </article>
        <div className="empty-placeholder"></div>
        <div className="challenge-results touchscreen__challenge-results">
          <Tabs titles={[{ name: 'Leaderboard' }]} items={[<ResultsTable />]} />
        </div>
      </section>
    </div>
  );
};

export default Challenge;
