import { isEmpty } from 'lodash';

export default (values) => {
  let errors = {};

  if (isEmpty(values.username)) {
    errors.username = true;
  }

  if (!isEmpty(values.firstname) && /[^a-zA-Z\s\'\-]/g.test(values.firstname)) {
    errors.firstname = "You can use letters, spaces, - and ' signs.";
  }

  if (!isEmpty(values.lastname) && /[^a-zA-Z\s\'\-]/g.test(values.lastname)) {
    errors.lastname = "You can use letters, spaces, - and ' signs.";
  }

  if (isEmpty(values.email)) {
    errors.email = true;
  }

  if (
    !isEmpty(values.email) &&
    !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      values.email,
    )
  ) {
    errors.email = 'Please provide correct email.';
  }

  return errors;
};
