import React from 'react';

import { Cardio } from '../../../../data/cardio.js';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//Assets
import collaboration_image from '../../../../../asset/resource/collaboration-image.png';
import medical_education_image from '../../../../../asset/resource/medical-education-image.png';
import education_image from '../../../../../asset/resource/education-image.png';

import './UseCases.scss';

const UseCases = () => {
  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <h2>
          <b>
            <span>TOUCH MY HEART</span>
            <br /> Use Cases
          </b>
          <Cardio />
        </h2>
        <div className="use-cases__slider-wrapper">
          <Carousel showArrows infiniteLoop showThumbs={false} showStatus={false}>
            <div className="use-cases__slide">
              <article>
                <h3>COLLABORATION</h3>
                <p>
                  Healthcare professionals can collectively examine a realistic 3D model of the heart to discuss and
                  exchange insights. They can see the model from various angles, see the interior of the heart with a
                  cross-section segmented view, and collaborate on how to use the model in their practice.
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={collaboration_image} className="collaboration-image" alt="collaboration image" />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>MEDICAL EDUCATION</h3>
                <p>
                  With the innovative representation of heartbeats, multiple views, and spatial audio, students can
                  explore examples of normal vs. abnormal heart functions. They can see the realistic 3D heart model
                  from various angles, view the heart in cross-section, and explore internal structures.
                </p>
              </article>
              <figure className="use-cases__image">
                <img
                  width="100%"
                  src={medical_education_image}
                  className="medical-education-image"
                  alt="medical education image"
                />
              </figure>
            </div>
            <div className="use-cases__slide">
              <article>
                <h3>PATIENT ENGAGEMENT</h3>
                <p>
                  Healthcare professionals can engage and educate patients by showing a heart model, explaining heart
                  functions, illustrating examples of normal and abnormal heart rates, and using the model to explain
                  procedures that may be performed
                </p>
              </article>
              <figure className="use-cases__image">
                <img width="100%" src={education_image} className="education-image" alt="education image" />
              </figure>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
