import axios from 'axios';

const apiInstance = axios.create({
  baseURL: process.env.API,
});

const usersListConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export async function getListOfUsers() {
  return await apiInstance.get('/results', usersListConfig()).then((response) => {
    return response.data;
  });
}

export async function postForm(data) {
  return await apiInstance.post('/users', data, usersListConfig()).then((response) => {
    return response.data;
  });
}
