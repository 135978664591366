import React, { useState } from 'react';

import Modal from './Modal/Modal';

import tmh_video_preview from '../../../../../asset/resource/tmh_video_preview.png';

import './DemoVideo.scss';

export const DemoVideo = () => {
  const [isOpened, setIsOpened] = useState(false);

  const openVideo = () => {
    setIsOpened(true);
  };

  const onClose = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) setIsOpened(false);
  };

  return (
    <section id="video" className="video-section">
      <div className="video-section__wrapper">
        <div className="video-section__preview" onClick={openVideo}>
          <figure>
            <img src={tmh_video_preview} alt="video preview image" />
          </figure>
        </div>
      </div>

      {isOpened && <Modal isOpened={isOpened} videoUrl={'8-3VOtKS6jY'} onClose={onClose} />}
    </section>
  );
};

export default DemoVideo;
