import React, { useState } from 'react';
import cn from 'classnames';
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

//Assets
import risk_assessment_image from '../../../../../asset/resource/risk-assessment-image.png';
import visualization_image from '../../../../../asset/resource/visualization-image.png';
import senses_image from '../../../../../asset/resource/senses_image.png';
import heart_side from '../../../../../asset/resource/heart_side.gif';

import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const [imageView, setImageView] = useState([]);
  const isMobile = useCheckMobile({ width: 767 });

  const handleViewImages = (inView, i) => {
    if (inView) {
      setImageView((prevArray) => [...prevArray, (imageView[i] = true)]);
    }
  };

  return (
    <section id="how-to-use" className="how-to-use">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={
          isMobile
            ? {
                visible: { y: 0, opacity: 1 },
              }
            : {
                visible: { x: 0, opacity: 1 },
                hidden: { x: -200, opacity: 0 },
              }
        }
        transition={{ type: 'anticipate', duration: 1, delay: 1 }}
      >
        <h2>How to use</h2>
      </motion.div>
      <div className="how-to-use__blocks">
        <div className="how-to-use__block one">
          <article>
            <h3>HEAR THE HEART BEATING</h3>
            <p>
              Users can hear what a normal heart rate sounds like compared to an abnormal one, such as heart rates with
              arrhythmia and aortic stenosis.
              <br />
              <br />
              By adding an immersive auditory dimension, a more realistic environment is created. Spatial audio can
              provide auditory cues that correspond to the spatial relationships of the heart structures.
            </p>
          </article>
          <InView triggerOnce onChange={(inView) => handleViewImages(inView, 0)} threshold={0.5}>
            <figure className={cn('how-to-use__image', imageView[0] && 'how-to-use__image-done')}>
              <img
                width="100%"
                src={risk_assessment_image}
                className="risk_assessment_image"
                alt="risk assessment image"
              />
            </figure>
          </InView>
        </div>

        <div className="how-to-use__block two">
          <article>
            <h3>CUT AND SLICE</h3>
            <p>
              Users can study how a heart works from the inside with a view of the chambers, valves, blood vessels, and
              arteries.
              <br />
              <br />
              This can allow for a comprehensive assessment of any abnormalities. Users can see the movement of the
              heart's walls during different phases of the cardiac cycle.
              <br />
              <br />
              Overall, heart slicing provides a comprehensive and detailed evaluation of the heart's anatomy, function,
              and potential abnormalities.
            </p>
          </article>
          <InView triggerOnce onChange={(inView) => handleViewImages(inView, 1)} threshold={0.5}>
            <figure className={cn('how-to-use__image', imageView[1] && 'how-to-use__image-done')}>
              <img src={heart_side} className="heart_side_image" alt="heart side image" />
            </figure>
          </InView>
        </div>

        <div className="how-to-use__block three">
          <article>
            <h3>EXPERIENCE A FUSION OF SENSES</h3>
            <p>
              By combining visual observation, sensational, and auscultation, users can gather comprehensive information
              about the heart's structure, function, and potential abnormalities.
              <br />
              <br />
              Spatial audio, haptic feedback, and a visual hologram of the heart enrich each other to create a truly
              immersive experience.
            </p>
          </article>
          <InView triggerOnce onChange={(inView) => handleViewImages(inView, 2)} threshold={0.5}>
            <figure className={cn('how-to-use__image', imageView[2] && 'how-to-use__image-done')}>
              <img width="100%" src={senses_image} className="senses_image" alt="senses image" />
            </figure>
          </InView>
        </div>

        <div className="how-to-use__block four">
          <article>
            <h3>INTERACT WITH A REALISTIC MODEL</h3>
            <p>
              Users can examine the digital twin via the realistic model from different angles, zoom in on areas of
              interest, and manipulate the model to better understand the anatomical relationships.
              <br />
              <br />
              Realistic 3D heart models can provide a more comprehensive and detailed visualization of the heart's
              structures.
              <br />
              <br />
              They can serve as valuable tools in modern cardiac care, facilitating more precise and personalized
              approaches to patient management.
            </p>
          </article>
          <InView triggerOnce onChange={(inView) => handleViewImages(inView, 3)} threshold={0.5}>
            <figure className={cn('how-to-use__image', imageView[3] && 'how-to-use__image-done')}>
              <img width="100%" src={visualization_image} className="visualization-image" alt="human heart image" />
            </figure>
          </InView>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
