import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Modal.scss';

const Modal = ({ videoUrl, isOpened, onClose, className }) => {
  return (
    <div className={cn('video-modal', className)}>
      {isOpened && (
        <div className="video-modal__modal" onClick={onClose}>
          <div className="video-modal__modal__content">
            <button className="video-modal__modal__close" onClick={onClose}>
              ✖
            </button>
            <iframe
              id="youtube"
              frameBorder="0"
              allowFullScreen
              width="100%"
              height="100%"
              src={`//www.youtube.com/embed/${videoUrl}?autoplay=1&rel=0`}
              sandbox="allow-same-origin allow-popups allow-scripts"
            />
          </div>
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  videoUrl: PropTypes.string,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Modal;
