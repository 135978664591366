import React, { useState } from 'react';
import cn from 'classnames';
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

//Assets
import xr_image from '../../../../../asset/resource/xr_image.png';
import haptics_image from '../../../../../asset/resource/haptics_image.png';
import spatial_audio from '../../../../../asset/resource/spatial_audio.png';

import { useCheckMobile } from '../../../../hooks';

import './HowItWorks.scss';

const HowItWorks = () => {
  const [blockView, setBlockView] = useState([]);
  const isMobile = useCheckMobile({ width: 767 });

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setBlockView((prevArray) => [...prevArray, (blockView[i] = true)]);
    }
  };

  return (
    <section id="how-it-work" className="how-it-works">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={
          isMobile
            ? {
                visible: { y: 0, opacity: 1 },
              }
            : {
                visible: { x: 0, opacity: 1 },
                hidden: { x: -200, opacity: 0 },
              }
        }
        transition={{ type: 'anticipate', duration: 1 }}
      >
        <h2>HOW IT WORKS</h2>
      </motion.div>
      <div className="how-it-works__blocks">
        <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 0)} threshold={0}>
          <div
            className={cn('how-it-works__block', blockView[0] && 'how-it-works__block-done', 'how-it-works__block_one')}
          >
            <figure>
              <img width="100%" src={xr_image} className="card-logo" alt="logo" />
            </figure>
            <figcaption>
              Touch My Heart uses <span>Magic Leap</span> – an augmented reality headset that brings digital objects
              into the real world.
              <br />
              <br />
              <span>Digital twin</span> technology provides a virtual environment with all required equipment,
              incorporating the human body or body parts.
              <br />
              <br />
              The <span>hand tracking system</span> identifies key points on the user's hands, allowing them to interact
              in mixed reality without a controller.
            </figcaption>
          </div>
        </InView>
        <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 1)} threshold={0.5}>
          <div
            className={cn('how-it-works__block', blockView[2] && 'how-it-works__block-done', 'how-it-works__block_two')}
          >
            <figure>
              <img width="100%" src={spatial_audio} className="card-logo" alt="logo" />
            </figure>
            <figcaption>
              A fully immersive audio experience is produced.
              <br /> The user can recognize the directions of static and moving sound objects (spatial location) and
              feel their connection with the visual objects. The user can also:
              <br />
              <ul>
                <li>Interact with the model</li>
                <li>Navigate by changing position and orientation</li>
                <li>Manipulate the sound objects</li>
              </ul>
            </figcaption>
          </div>
        </InView>
        <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 3)} threshold={1}>
          <div
            className={cn(
              'how-it-works__block',
              blockView[1] && 'how-it-works__block-done',
              'how-it-works__block_three',
            )}
          >
            <figure>
              <img width="100%" src={haptics_image} className="card-logo" alt="logo" />
            </figure>
            <figcaption>
              <span>Ultraleap</span> provides accurate hand tracking and natural tactile feedback for the sensation of
              touch in mid-air. <br />
              <br />
              Haptic feedback is used to engage more of the user's senses and provide a deeper and more immersive
              experience.
              <br />
              <br />
              The device enhances a sense of touch, making the virtual simulation more realistic.
              <br />
              <br />
              The combined ultrasound waves have enough force to create pressure points on skin which are used to create
              a vibration that touch sensors in hands can detect.
            </figcaption>
          </div>
        </InView>
      </div>
    </section>
  );
};

export default HowItWorks;
